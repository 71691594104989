import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import {
  useNavigate,
  Routes,
  Route,
  useLocation,
  NavLink,
} from "react-router-dom";
import {
  ClickAwayListener,
  CssBaseline,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  paperClasses,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { SECTION_MIN_HEIGHT } from "../utils/constants";
import { useEffect, useState } from "react";
import MainContainer from "../shared/components/MainContainer";
import BottomListItems from "./SidebarList/BottomListItems";
import SubItemList from "./SidebarList/SubItemsList";
import { ImageContainer } from "../shared/components/ImageContainer";
import { LogoDark, WikiLogo } from "../assets/icons/icons";
import { DrawerExpandlessIcon } from "../assets/icons/DrawerExpandlessIcon";
import { mainNavigation } from "./navigation/navigation";
import { useGlobalContext } from "../App";
import Page from "../pages/Page";
import CustomBreadCrumb from "../shared/components/BreadCrumb";
import { capitalizeWords } from "../utils/extensions";
import Home from "../pages/home/Home";
import User from "./User";
import Products from "../pages/products/Products";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../shared/components/button/Buttons";
import { ToastContainer } from "react-toastify";
import { toastError, toastSuccess } from "../utils/ToastMessage";
import { setLocalStorageItem } from "../utils/localstorage";
import Cookies from "js-cookie";
import { createComponent, updateComponent } from "../services/component";
import FeedbacksList from "../pages/feedbacks/FeedbacksList";
import ReportsList from "../pages/reports/ReportsList";
import ReportDeailScreen from "../pages/reports/ReportDeailScreen";
import FeedbackDeailScreen from "../pages/feedbacks/FeedbackDeailScreen";

// sidebar width
const drawerWidth = 264;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  borderRight: "1px solid rgba(255, 255, 255, 0.12)",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    maxWidth: `100%`,
    opacity: 1,
    backgroundColor: "background.default", // Ensure full opacity black
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: "background.default", // Ensure full opacity black
  boxShadow: "none !important", // Disable box shadow to avoid lightening effect
  borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
  height: "76px",
  opacity: 1,
  marginRight: "0px",
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Dashboard: React.FC = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    navigations,
    setNavigations,
    activeNavigation,
    setActiveNavigation,
    breadcrumbs,
    setBreadcrumbs,
    breadCrumbsLoading,
    componentDetailsList,
    formComplete,
    setFormComplete,
    setActiveSaveAsDraftDialog,
    userDetails,
    setComponentDetailsList,
  } = useGlobalContext();

  const sidebarBreakpoint = useMediaQuery(theme.breakpoints.up(1280));

  const [componentSaveLoading, setComponentSaveLoading] =
    useState<boolean>(false);
  const [componentSaveAsDraftLoading, setComponentSaveAsDraftLoading] =
    useState<boolean>(false);

  // welcome dialog state
  const [open, setOpen] = React.useState(true);

  const [showSidemenu, setShowSidemenu] = useState<boolean>(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    if (window.innerWidth < 1280) setShowSidemenu(false);
    else {
      setOpen(false);
    }
  };

  const [collapsed, setCollapsed] = React.useState<Array<boolean>>(
    window.localStorage.getItem("collapsed")
      ? JSON.parse(window.localStorage.getItem("collapsed") || "")
      : [true, true, true, true, true, true, true]
  );

  const toggleCollapse = (index: number) => {
    setCollapsed((prevCollapsed) => {
      const collapsedState = prevCollapsed.map((_, i) =>
        i === index ? !prevCollapsed[i] : true
      );
      window.localStorage.setItem("collapsed", JSON.stringify(collapsedState));
      return collapsedState;
    });
  };

  const updateBreadcrumbs = (customTitle?: string) => {
    const pathnames = location.pathname.split("/").filter((x) => x);

    // If pathnames array is empty (i.e., we are at the root "/"), set Dashboard breadcrumb
    if (pathnames.length === 0) {
      setBreadcrumbs([
        <NavLink key={"1"} to={`/`}>
          Dashboard
        </NavLink>,
      ]);
      return;
    }

    const breadcrumbLabel = customTitle
      ? customTitle.replace(/-/g, " ")
      : capitalizeWords(pathnames[0]?.replace(/-/g, " "));

    setBreadcrumbs([
      <NavLink key={"0"} to={`/${pathnames[0]}`}>
        {breadcrumbLabel}
      </NavLink>,
    ]);
  };

  useEffect(() => {
    updateBreadcrumbs();
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if (open === false) {
      setCollapsed((prev: any) => prev.map(() => true));
    }
  }, [open]);

  useEffect(() => {
    // Update activeNavigation based on current URL path
    const currentPath = location.pathname;
    const activeNavItem = navigations.find(
      (item: any) =>
        item.to === currentPath ||
        item?.subNavigations?.some((subItem: any) => subItem.to === currentPath)
    );
    if (activeNavItem) {
      setActiveNavigation(activeNavItem);
    }
  }, [location, navigations, setActiveNavigation]);

  useEffect(() => {
    window.localStorage.setItem(
      "ACTIVE_NAVIGATION",
      JSON.stringify(activeNavigation)
    );
  }, [activeNavigation]);

  useEffect(() => {
    setNavigations(mainNavigation);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {}, [breadcrumbs, formComplete, userDetails]);

  // -----------------Components Create and Update handling functions----------------------
  const handleSaveComponent = async () => {
    setComponentSaveLoading(true);

    const components = [...componentDetailsList];
    const failedComponents: any = [];

    for (let component of components) {
      try {
        const files = component["documents"];
        component["status"] = "ACTIVE";
        delete component["documents"];
        await createComponent(component, files)
          .then(() => {
            setFormComplete(true);
          })
          .catch((err: any) => {
            failedComponents.push(component);
          });
      } catch (error) {
        failedComponents.push(component); // Catch unexpected errors and track failed component
        console.log(error);
      }
    }

    if (failedComponents.length === 0) {
      Cookies.remove("ACTIVE_PRODUCT_COMPONENT_FORM_DRAFT");
      toastSuccess(
        "Successfully Uploaded!",
        "Product components are successfully uploaded."
      );
    } else {
      const failedComponentNames = failedComponents.map(
        (comp: any) => comp.title
      );
      toastError(
        "Ops! Something went wrong.",
        `Could not upload the following components: ${failedComponentNames.join(
          ", "
        )}`
      );
      setComponentDetailsList(failedComponents); // Set the failed components for retry
    }

    navigate("/product");
    setComponentSaveLoading(false); // Ensure loading is stopped
  };

  const handleSaveComponentAsDraft = async () => {
    setComponentSaveAsDraftLoading(true);
    for (let component of componentDetailsList) {
      try {
        const files = component["documents"];
        component["status"] = "DRAFT";
        delete component["documents"];

        await createComponent(component, files)
          .then(() => {
            setComponentSaveAsDraftLoading(false);
            setFormComplete(true);
            navigate("/product");
            toastSuccess(
              "Successfully saved as draft!",
              "Product components are saved as draft."
            );
          })
          .catch((err: any) => {
            console.log(err);
            setComponentSaveAsDraftLoading(false);
            toastError(
              "Ops! Something went wrong.",
              "Could not save the components."
            );
          });
      } catch (error) {
        setComponentSaveAsDraftLoading(false);
        toastError(
          "Ops! Something went wrong.",
          "Could not save the components."
        );
      }
    }
  };

  const handleEditComponent = async () => {
    setComponentSaveLoading(true);
    try {
      let files = [];
      let component: any = componentDetailsList[0] || [];
      component["status"] = "ACTIVE";

      if (!component.documents[0].componentId) {
        component["isThumbnailUpdated"] = true;
        files = component["documents"];
        delete component["documents"];
      }
      await updateComponent(component?.id, component, files)
        .then((res: any) => {
          toastSuccess("Successfully Updated!", res?.message);
          navigate("/product");
          setComponentSaveLoading(false);
        })
        .catch((err: any) => {
          console.log(err);
          setComponentSaveLoading(false);
          toastError(
            "Ops! Something went wrong.",
            "Could not update the component."
          );
        });
    } catch (error) {
      setComponentSaveLoading(false);
      toastError(
        "Ops! Something went wrong.",
        "Could not update the component."
      );
    }
  };

  const handleEditMultipleComponent = async () => {
    setComponentSaveLoading(true);

    //Update Component based on it's id
    for (let component of componentDetailsList) {
      let files = [];
      component["status"] = "ACTIVE";

      if (!component.documents[0].componentId) {
        component["isThumbnailUpdated"] = true;
        files = component["documents"];
        delete component["documents"];
      }
      await updateComponent(component?.id, component, files);
    }
    navigate("/product");
    setComponentSaveLoading(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "background.main",
        width: "100%",
        justifyContent: "center",
        maxWidth: "100%",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "block",
          bgcolor: "background.main",
          width: "100%",
          justifyContent: "center",
          maxWidth: "2320px",
          maxHeight: "100vh",
          alignItems: "center",
        }}
      >
        <CssBaseline />
        <AppBar
          position="absolute"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-start",
            backgroundColor: "background.default",
            marginRight: !open ? "0px" : "0px",
            flex: "1",
            opacity: 1,
            width: {
              xs: "100%",
              lg: open
                ? `calc(100% - (${drawerWidth}px + 0px))`
                : `calc(100% - 102px)`,
            },
            maxWidth: {
              xs: "100%",
              lg: open
                ? `calc(100% - (${drawerWidth}px + 0px))`
                : `calc(100% - 102px)`,
            },
            transition: theme.transitions.create(["width"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            [theme.breakpoints.down("lg")]: {
              width: `calc(100% - 0px)`,
              marginLeft: 0,
              ...(open && {
                transition: theme.transitions.create(["margin"], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
              }),
            },
          }}
          open={open}
        >
          {sidebarBreakpoint ? (
            <></>
          ) : (
            <Toolbar
              sx={{
                bgcolor: "black",
                backgroundImage: "none",
                padding: "0px !important",
                paddingLeft: theme.breakpoints.down("lg")
                  ? "40px !important"
                  : "0px",
                minHeight: "100% !important",
                width: "40px !important",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                sx={{
                  marginLeft: "0px",
                  bgcolor: "black",
                  [theme.breakpoints.up("lg")]: {
                    display: "none",
                  },
                }}
                onClick={() => {
                  setShowSidemenu((prev) => !prev);
                  setOpen(true);
                }}
              >
                <MenuIcon style={{ color: "white" }} />
              </IconButton>
            </Toolbar>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "background.default",
              alignItems: "center",
              flex: 1,
              height: "100%",
            }}
          >
            <MainContainer
              styleMainContainer={{
                display: "flex",
                justifyContent: "center",
                width: {
                  xs: "100%",
                  lg: open
                    ? `calc(100% - (${drawerWidth}px))`
                    : `calc(100vw - 262px)`,
                },
                maxWidth: {
                  xs: "100%",
                  lg: open
                    ? `calc(100% - (${drawerWidth}px))`
                    : `calc(100vw - 262px)`,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "space-between",
                  height: "100%",
                  bgcolor: "background.default",
                  maxWidth: "1747px",
                  // paddingX: "40px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {!breadCrumbsLoading && (
                    <CustomBreadCrumb
                      style={{
                        marginBottom: "0px !important",
                        color: "white !important",
                      }}
                      breadcrumbs={breadcrumbs}
                    />
                  )}
                </Box>

                <Box
                  display="flex"
                  sx={{
                    justifyContent: "flex-end",
                    alignItems: "center",
                    [theme.breakpoints.down("md")]: {
                      justifyContent: "flex-end",
                    },
                  }}
                >
                  {window.location.pathname === "/" ? (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifySelf="flex-end"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <User className="user" userDetails={userDetails} />
                      </Box>
                    </Box>
                  ) : window.location.pathname?.includes(
                      "/product/component/create"
                    ) ? (
                    <Box
                      sx={{ gap: "24px" }}
                      display="flex"
                      alignItems="center"
                      justifySelf="flex-end"
                    >
                      <ButtonSecondary
                        sx={{
                          height: "46px",
                          bgcolor: "rgba(255, 255, 255, 0.06) !important",
                        }}
                        onClick={() => {
                          // Cookies.set(
                          //   "ACTIVE_PRODUCT_COMPONENT_FORM_DRAFT",
                          //   componentDetailsList
                          // );
                          handleSaveComponentAsDraft();
                          setFormComplete(true);
                        }}
                        isLoading={componentSaveAsDraftLoading}
                        LabelStyle={{ fontSize: "18px !important" }}
                        label="Save as draft"
                      />

                      <ButtonPrimary
                        sx={{
                          height: "45px !important",
                          border: "14px !important",
                          width: "112px",
                        }}
                        onClick={() => {
                          handleSaveComponent();
                        }}
                        isLoading={componentSaveLoading}
                        LabelStyle={{ fontSize: "18px !important" }}
                        label="Save"
                      />
                    </Box>
                  ) : window.location.pathname?.includes(
                      "/product/component/edit"
                    ) ? (
                    <Box
                      sx={{ gap: "24px" }}
                      display="flex"
                      alignItems="center"
                      justifySelf="flex-end"
                    >
                      <ButtonPrimary
                        sx={{
                          height: "45px !important",
                          border: "14px !important",
                          width: "112px",
                        }}
                        onClick={() => {
                          handleEditComponent();
                        }}
                        isLoading={componentSaveLoading}
                        LabelStyle={{ fontSize: "18px !important" }}
                        label="Save"
                      />
                    </Box>
                  ) : window.location.pathname?.includes(
                      "/product/component/multiple-edit"
                    ) ? (
                    <Box
                      sx={{ gap: "24px" }}
                      display="flex"
                      alignItems="center"
                      justifySelf="flex-end"
                    >
                      <ButtonPrimary
                        sx={{
                          height: "45px !important",
                          border: "14px !important",
                          width: "112px",
                        }}
                        onClick={() => {
                          handleEditMultipleComponent();
                        }}
                        isLoading={componentSaveLoading}
                        LabelStyle={{ fontSize: "18px !important" }}
                        label="Save"
                      />
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
            </MainContainer>
          </Box>
        </AppBar>

        {sidebarBreakpoint ? (
          <Drawer
            sx={{
              display: { xs: showSidemenu ? "block" : "none", lg: "block" },
              [`& .${paperClasses.root}`]: {
                backgroundColor: "transparent",
                margin: "0px 0px 0px 0px",
                minWidth: open ? drawerWidth : "102px",
                position: "absolute",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              },
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            variant="permanent"
            open={open}
          >
            {/* Toggle expand button */}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                width: "100%",
                borderRadius: "0px",
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "transparent",
                },
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon sx={{ width: "100%", height: "100%", opacity: 0 }} />
            </IconButton>

            <DrawerHeader
              sx={{
                display: "flex",
                justifyContent: open ? "space-between" : "center",
                margin: open ? "16px 24px 40px 24px" : "16px 0px 40px 0px",
                alignContent: "center",
              }}
            >
              {open ? (
                <ImageContainer
                  style={{
                    width: "54px",
                    minWidth: "54px",
                    height: "54px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  height="54px"
                  title="Main Logo"
                >
                  {WikiLogo}
                </ImageContainer>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                    width: "100%",
                    padding: "16px 0px 0px 0px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <ImageContainer
                      style={{
                        maxWidth: "42px",
                      }}
                      width="50px"
                      height="35px"
                      title="Main Logo"
                    >
                      {LogoDark}
                    </ImageContainer>
                  </Box>
                  <Box
                    style={{
                      backgroundColor: "#e1dede",
                      width: "100%",
                      margin: "0px",
                      height: "1px",
                    }}
                  />
                </Box>
              )}
              {/* Toggle collapse button */}
              {open && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "fit-content",
                    height: "fit-content",
                  }}
                  onClick={handleDrawerClose}
                >
                  <DrawerExpandlessIcon color="black" />
                </Box>
              )}
            </DrawerHeader>

            <Box
              display="flex"
              sx={{
                justifyContent: "space-between",
                width: "100%",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <List
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                    alignItems: "center",
                  }}
                >
                  {navigations
                    ?.filter((a: any) => a !== null)
                    ?.map((item: any, index: number) => {
                      return (
                        <ListItem
                          key={item.text}
                          sx={{
                            display: "block",
                            paddingY: "0px",
                            paddingLeft: open ? "24px" : "16px",
                            paddingRight: open ? "24px" : "16px",
                            justifyContent: "center",
                            alignItems: "center",
                            width: open ? "100%" : "fit-content",
                          }}
                        >
                          <ListItemButton
                            disableRipple
                            sx={{
                              minHeight: open ? "52px" : "60px",
                              boxShadow:
                                activeNavigation?.text === item?.text
                                  ? "inset 0px 0px 0px 1px #3D3D3D"
                                  : "none",
                              width: open ? "100%" : "60px",
                              display: "flex",
                              justifyContent: open ? "initial" : "center",
                              borderRadius: open ? "12px" : "50%",
                              paddingX: "12px",
                              bgcolor:
                                (open &&
                                  activeNavigation?.text === item?.text) ||
                                item?.subNavigations?.some(
                                  (subItem: any) =>
                                    subItem?.text === activeNavigation?.text
                                )
                                  ? "secondary.main"
                                  : "transparent",
                              "&:hover": {
                                bgcolor:
                                  activeNavigation?.text === item?.text
                                    ? "secondary.main"
                                    : "transparent",
                              },
                            }}
                            onClick={(e) => {
                              if (!formComplete) {
                                e.preventDefault();
                                setActiveSaveAsDraftDialog(true);
                                setLocalStorageItem("NavigateTo", item?.to);
                              } else {
                                toggleCollapse(index);
                                setActiveNavigation((prevNav: any) => {
                                  window.localStorage.setItem(
                                    "ACTIVE_NAVIGATION",
                                    JSON.stringify(
                                      item?.subNavigations
                                        ? JSON.stringify(prevNav)
                                        : JSON.stringify(item)
                                    )
                                  );
                                  return item?.subNavigations ? prevNav : item;
                                });

                                navigate(item?.to);
                              }
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                mr: open ? 2 : "auto",
                                justifyContent: "center",
                                width: "fit-content",
                                minWidth: open ? "fit-content" : "60px",
                              }}
                            >
                              {activeNavigation?.text === item?.text ? (
                                item?.iconSelected ? (
                                  item?.iconSelected
                                ) : (
                                  <></>
                                )
                              ) : item?.icon ? (
                                item?.icon
                              ) : (
                                <></>
                              )}
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="subtitle1"
                                  style={{
                                    color:
                                      activeNavigation?.text === item?.text
                                        ? "#FFFFFF"
                                        : "rgba(255, 255, 255, 0.5)",
                                    fontWeight: 500,
                                    lineHeight: "100%",
                                    marginBottom: "0px",
                                  }}
                                >
                                  {item.text}
                                </Typography>
                              }
                              sx={{ opacity: open ? 1 : 0 }}
                            />
                          </ListItemButton>
                          {!collapsed[index] && (
                            <>
                              {item?.subNavigations && (
                                <SubItemList
                                  activeNavigation={activeNavigation}
                                  setActiveNavigation={setActiveNavigation}
                                  open={open}
                                  menuList={item?.subNavigations}
                                />
                              )}
                            </>
                          )}
                        </ListItem>
                      );
                    })}
                </List>
              </Box>
              <Box
                sx={{
                  paddingX: open ? "24px" : "8px",
                }}
              >
                <BottomListItems
                  setActiveNavigation={setActiveNavigation}
                  activeNavigation={activeNavigation}
                  helpCenterBadgeText=""
                  open={open}
                />
              </Box>
            </Box>
          </Drawer>
        ) : (
          showSidemenu && (
            <ClickAwayListener
              onClickAway={() => {
                setShowSidemenu(false);
              }}
            >
              <Drawer
                sx={{
                  display: { xs: showSidemenu ? "block" : "none", lg: "block" },
                  [`& .${paperClasses.root}`]: {
                    backgroundColor: "black",
                    margin: "16px 0px 16px 16px",
                    borderRadius: "16px",
                    maxHeight: `calc(100vh - 32px)`,
                    minWidth: "76px",
                    position: "absolute",
                    boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                    zIndex: 100000,
                    "&:hover": {
                      backgroundColor: "black",
                    },
                  },
                  backgroundColor: "black",
                  zIndex: 100000,
                  "&:hover": {
                    backgroundColor: "black",
                  },
                }}
                variant="permanent"
                open={open}
              >
                {/* Toggle expand button */}
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    zIndex: 1,
                    width: "100%",
                    borderRadius: "0px",
                    ...(open && { display: "none" }),
                  }}
                >
                  <MenuIcon
                    sx={{ width: "100%", height: "100%", opacity: 0 }}
                  />
                </IconButton>

                <DrawerHeader
                  sx={{
                    display: "flex",
                    justifyContent: open ? "space-between" : "center",
                    margin: open ? "16px 24px 40px 24px" : "16px 0px 0px 0px",
                    alignContent: "center",
                  }}
                >
                  {open ? (
                    <ImageContainer
                      style={{ width: "54px", height: "54px" }}
                      height="54px"
                      title="Main Logo"
                    >
                      {WikiLogo}
                    </ImageContainer>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        padding: "16px 0px 0px 0px",
                      }}
                    >
                      <ImageContainer
                        style={{
                          maxWidth: "54px",
                        }}
                        width="54px"
                        height="54px"
                        title="Main Logo"
                      >
                        {WikiLogo}
                      </ImageContainer>
                    </Box>
                  )}
                  {/* Toggle collapse button */}
                  {open && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "fit-content",
                        height: "fit-content",
                      }}
                      onClick={handleDrawerClose}
                    >
                      <DrawerExpandlessIcon color="white" />
                    </Box>
                  )}
                </DrawerHeader>

                <Box
                  display="flex"
                  sx={{
                    justifyContent: "space-between",
                    width: "100%",
                    height: "100%",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <List>
                      {navigations
                        ?.filter((a: any) => a !== null)
                        ?.map((item: any, index: number) => {
                          return (
                            <ListItem
                              key={item.text}
                              sx={{
                                display: "block",
                                paddingY: "0px",
                                paddingLeft: open ? "24px" : "16px",
                                paddingRight: open ? "24px" : "16px",
                              }}
                            >
                              <ListItemButton
                                disableRipple
                                sx={{
                                  minHeight: open ? "52px" : "60px",
                                  boxShadow:
                                    activeNavigation?.text === item?.text
                                      ? "inset 0px 0px 0px 1px #3D3D3D"
                                      : "none",
                                  width: open ? "100%" : "60px",
                                  display: "flex",
                                  justifyContent: open ? "initial" : "center",
                                  borderRadius: open ? "12px" : "50%",
                                  paddingX: "12px",
                                  bgcolor:
                                    (open &&
                                      activeNavigation?.text === item?.text) ||
                                    item?.subNavigations?.some(
                                      (subItem: any) =>
                                        subItem?.text === activeNavigation?.text
                                    )
                                      ? "secondary.main"
                                      : "transparent",
                                  "&:hover": {
                                    bgcolor:
                                      activeNavigation?.text === item?.text
                                        ? "secondary.main"
                                        : "transparent",
                                  },
                                }}
                                onClick={(e) => {
                                  if (!formComplete) {
                                    e.preventDefault();
                                    setActiveSaveAsDraftDialog(true);
                                    setLocalStorageItem("NavigateTo", item?.to);
                                  } else {
                                    toggleCollapse(index);
                                    setActiveNavigation((prevNav: any) => {
                                      window.localStorage.setItem(
                                        "ACTIVE_NAVIGATION",
                                        JSON.stringify(
                                          item?.subNavigations
                                            ? JSON.stringify(prevNav)
                                            : JSON.stringify(item)
                                        )
                                      );
                                      return item?.subNavigations
                                        ? prevNav
                                        : item;
                                    });

                                    navigate(item?.to);
                                  }
                                }}
                              >
                                <ListItemIcon
                                  sx={{
                                    mr: open ? 2 : "auto",
                                    justifyContent: "center",
                                    width: "fit-content",
                                    minWidth: open ? "fit-content" : "60px",
                                  }}
                                >
                                  {activeNavigation?.text === item?.text ? (
                                    item?.iconSelected ? (
                                      item?.iconSelected
                                    ) : (
                                      <></>
                                    )
                                  ) : item?.icon ? (
                                    item?.icon
                                  ) : (
                                    <></>
                                  )}
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <Typography
                                      variant="subtitle1"
                                      style={{
                                        color:
                                          activeNavigation?.text === item?.text
                                            ? "#FFFFFF"
                                            : "rgba(255, 255, 255, 0.5)",
                                        fontWeight: 500,
                                        lineHeight: "100%",
                                        marginBottom: "0px",
                                      }}
                                    >
                                      {item.text}
                                    </Typography>
                                  }
                                  sx={{ opacity: open ? 1 : 0 }}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                    </List>
                  </Box>
                  <Box>
                    <BottomListItems
                      setActiveNavigation={setActiveNavigation}
                      activeNavigation={activeNavigation}
                      helpCenterBadgeText=""
                      open={open}
                    />
                  </Box>
                </Box>
              </Drawer>
            </ClickAwayListener>
          )
        )}

        <Box
          sx={{
            width: {
              xs: "100%",
              lg: open
                ? `calc(100% - (${drawerWidth}px))`
                : `calc(100vw - 262px)`,
            },
            maxWidth: {
              xs: "100%",
              lg: open
                ? `calc(100% - (${drawerWidth}px))`
                : `calc(100vw - 262px)`,
            },
            minHeight: SECTION_MIN_HEIGHT,
            bgcolor: "transparent",
            marginLeft: {
              xs: "0px",
              lg: open ? `calc(${drawerWidth}px + 0px)` : "202px",
            },
            marginTop: "76px",
            paddingX: { xs: "16px", lg: "0px" },
          }}
        >
          <MainContainer
            styleMainContainer={{
              width: "100%",
              backgroundColor: "transparent",
              height: "100%",
            }}
          >
            <Box
              component="main"
              sx={{
                width: "100%",
                position: "relative !important",
                maxWidth: {
                  md: "100%",
                },
              }}
            >
              {/* <DrawerHeader /> */}
              {/* DASHBOARD ROUTES */}
              <Routes>
                <Route
                  path="/"
                  element={
                    <Page>
                      <Home />
                    </Page>
                  }
                />

                <Route
                  path="/product/*"
                  element={
                    <Page>
                      <Products />
                    </Page>
                  }
                />

                <Route
                  path="/reports"
                  element={
                    <Page>
                      <ReportsList />
                    </Page>
                  }
                />

                <Route
                  path="/reports/:id"
                  element={
                    <Page>
                      <ReportDeailScreen />
                    </Page>
                  }
                />

                <Route
                  path="/feedbacks"
                  element={
                    <Page>
                      <FeedbacksList />
                    </Page>
                  }
                />

                <Route
                  path="/feedbacks/:id"
                  element={
                    <Page>
                      <FeedbackDeailScreen />
                    </Page>
                  }
                />
              </Routes>
            </Box>
          </MainContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
